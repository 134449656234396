<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                中科大线上分享｜如何量化地找工作？
              </p>
              <div class="fst-italic mb-2">2023年8月3日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >校园宣讲</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/52/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    7月30日，非凸科技受邀参加由中国科学技术大学管理学院学生会、超级量化共同组织的“打开量化私募的黑箱”线上活动，分享量化前沿以及求职经验，助力同学们拿到心仪的offer。
                  </p>
                  <p>
                    活动上，非凸科技量化策略负责人陆一洲从多个角度分享了如何用量化的方法拿更好的offer。陆一洲表示，“量化交易与找工作有着很高的相似度，想要打开量化交易的黑箱，需要先从量化地找工作开始。对量化的传统解释是，使用过去所积累的信息去预测未来，要在市场中持续做期望更优的决策。然而针对期望，我们需要做大量的积累，从数据到因子，再到模型，最后到策略。”首先，陆一洲对offer的期望做出了假设，即“offer期望收益=offer本身价值*人在公司的成长期望值*公司所在行业的成长期望值*行业在整个人类社会中的成长期望值”。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/52/02-期望.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    接下来，陆一洲通过举例“文物记载、约会问题”来说明：更多数据的积累，可以帮助你做更好的决策。对于面试，从量化的角度，要求我们去做足够多的面试，才能达到更好的效果。
                  </p>
                  <p>
                    然而，数据到最终的决策，需要归纳成有效的因子。陆一洲通过“泰勒斯租橄榄油压机”事件发现：因子虽然需要归纳推演，但因子之间也存在着强弱关系，需要优先花时间和思考力在更重要的因子上。“行业的重要性可能远大于公司的成长性，而公司的成长性可能远大于人在公司中的成长性，也就是说行业的发展是最重要的。”陆一洲补充道。
                  </p>
                  <p>
                    关于offer本身价值，取决于“个人的内在价值*人在公司的表现系数*公司识人能力”。陆一洲表示，“长期来看，内在价值是一件比较难以改变的事，那么就需要多花时间去提升我们的表现系数以及公司的识人能力。表现系数，通俗来讲是你在面试时的表现，要做到尽量的不谦虚。而公司的识人能力，本身也很难被改变，那么在面试过程中，我们可以尝试申请与更高级别的前辈进行面聊，发现与公司的契合度。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/52/03-因子.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    对于面试来说，人脑是最好的模型，你需要通过不断的面试来持续优化自己。陆一洲表示，“量化策略，就是你的一套方法，对你的目标函数进行分解，分解之后，每一块都要想办法做到最优，然而也要把有限的时间花在更有价值的事情上。”
                  </p>
                  <p>
                    关于“表现系数”，更多靠练习。“很多人花时间练习面试的表现，但这些在未来工作中无法体现，不能提升其‘内在价值’；然而，在股票市场中，又是不得不做的一件事。所以，在做宏观预测时，非凸科技能够把微观的事情也做了，通过强大的机器学习能力、大量的底层数据积累能力以及前沿的Rust应用能力，帮助投资者做到更准确地预测，从而节省更多的交易成本。”陆一洲说道。
                  </p>
                  <p>
                    量化市场，蓬勃发展，未来可期。欢迎感兴趣的同学，加入非凸科技！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News52",
};
</script>

<style></style>
